.marker-cluster div {
  width: 32px;
  height: 32px;

  text-align: center;
  font: 14px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #fff;

  background-clip: padding-box;
  border-radius: 16px;
  background-color: #546587;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.marker-cluster span {
  line-height: 24px;
}
